import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { useFirebase } from "react-redux-firebase";
import { formatFbError, types } from "../../services/formatFbError";
import { Breadcrumb, Form, Input, notification } from "antd";
import { WEBSITE_NAME } from "../../constants/CompanyConstants";
import {
  META_RESET_PASSWORD_DESCRIPTION,
  META_RESET_PASSWORD_TITLE,
} from "../../constants/MetaTagsConstants";
import BreadcrumbContainer from "../../wrappers/breadcrumb/BreadcrumbContainer";
import { Link } from "react-router-dom";
import urls from "../../urls";
import { HomeOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import AntdCtaBtn from "../../components/cta/AntdCtaBtn";
import { fbTrackPageView } from "../../helpers/facebook";

const LoginRegister = () => {
  // state
  const [email, setEmail] = useState("");
  const [resetEmailInputError, setResetEmailInputError] = useState(null);
  // hooks
  const firebase = useFirebase();
  const { t } = useTranslation();

  useEffect(() => {
    fbTrackPageView();
  }, []);

  const handleResetPassword = () => {
    if (!!email) {
      firebase
        .resetPassword(email)
        .then(() => {
          setResetEmailInputError(null);
          setEmail("");
          notification["success"]({
            message: t("notifications.reset_email_sent"),
          });
        })
        .catch((error) => {
          let errorMessage = formatFbError(error.code);
          if (errorMessage.type === types.email) {
            setResetEmailInputError(errorMessage.text);
          } else
            notification["warning"]({
              message: errorMessage.text,
            });
        });
    } else setResetEmailInputError(t("errors.empty_email"));
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {META_RESET_PASSWORD_TITLE} · {WEBSITE_NAME}
        </title>
        <meta name="description" content={META_RESET_PASSWORD_DESCRIPTION} />
      </Helmet>
      <Layout headerTop="visible">
        {/* Breadcrumb */}
        <BreadcrumbContainer>
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to={urls.home}>
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("reset_password")}</Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbContainer>
        <div className="section-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                <div className="login-register-wrapper">
                  <Tab.Container defaultActiveKey="reset-password">
                    <Nav variant="pills" className="login-register-tab-list">
                      <Nav.Item>
                        <Nav.Link eventKey="reset-password">
                          <h4>{t("reset_password")}</h4>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="reset-password">
                        <div className="login-form-container">
                          <Form
                            layout="vertical"
                            name="reset-password-form"
                            onFinish={handleResetPassword}
                          >
                            <Form.Item
                              name="email"
                              label={t("email")}
                              onChange={(e) => setEmail(e.target.value)}
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_email"),
                                },
                                {
                                  type: "email",
                                  message: t("form.enter_valid_email"),
                                },
                              ]}
                              hasFeedback={!!resetEmailInputError}
                              help={resetEmailInputError}
                            >
                              <Input size={"large"} />
                            </Form.Item>
                            <Form.Item className="mt-2">
                              <AntdCtaBtn
                                type="primary"
                                htmlType="submit"
                                block
                                size={"large"}
                              >
                                <span>{t("reset")}</span>
                              </AntdCtaBtn>
                            </Form.Item>
                          </Form>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
  };
};

export default connect(mapStateToProps)(LoginRegister);
