import React from "react";

const BreadcrumbContainer = ({ children }) => {
  return (
    <div className="breadcrumb-area">
      <div className="container">{children}</div>
    </div>
  );
};

export default BreadcrumbContainer;
