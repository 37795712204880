import firebase from "firebase";
import { CURRENCY_CODE } from "../constants/DeliveryConstants";
import { getDiscountPriceWithDefault } from "./product";
import {
  ANALYTICS_ADD_TO_CART,
  ANALYTICS_SELECT_ITEM,
  RELATED_PRODUCTS_LIST_ID,
  RELATED_PRODUCTS_LIST_NAME,
} from "../constants/AnalyticsConstants";

/**
 * Triggers select_item event when clicking on related product
 **/
export const setSelectRelatedProductAnalytics = (product, index) => {
  firebase.analytics().logEvent(ANALYTICS_SELECT_ITEM, {
    item_list_id: RELATED_PRODUCTS_LIST_ID,
    item_list_name: RELATED_PRODUCTS_LIST_NAME,
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        currency: CURRENCY_CODE,
        discount:
          product.price -
          getDiscountPriceWithDefault(product.price, product.discount),
        index: index,
        item_list_id: RELATED_PRODUCTS_LIST_ID,
        item_list_name: RELATED_PRODUCTS_LIST_NAME,
        price: product.price,
        quantity: 1,
      },
    ],
  });
};

/**
 * Triggers add_to_cart event when adding related product directly to cart
 **/
export const setAddToCartAnalyticsRelatedProducts = (product, index) => {
  firebase.analytics().logEvent(ANALYTICS_ADD_TO_CART, {
    currency: CURRENCY_CODE,
    value: getDiscountPriceWithDefault(product.price, product.discount),
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        currency: CURRENCY_CODE,
        discount:
          product.price -
          getDiscountPriceWithDefault(product.price, product.discount),
        index: index,
        item_list_id: RELATED_PRODUCTS_LIST_ID,
        item_list_name: RELATED_PRODUCTS_LIST_NAME,
        price: product.price,
        quantity: 1,
      },
    ],
  });
};

/**
 * Dynamic analytics event for 1 specific item
 **/
export const setProductAnalyticsEvent = (eventName, product, quantity) => {
  const value = getDiscountPriceWithDefault(product.price, product.discount);
  firebase.analytics().logEvent(eventName, {
    currency: CURRENCY_CODE,
    value: !!quantity ? value * quantity : value,
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        currency: CURRENCY_CODE,
        discount:
          product.price -
          getDiscountPriceWithDefault(product.price, product.discount),
        index: 0,
        price: product.price,
        quantity: !!quantity ? quantity : 1,
      },
    ],
  });
};
