import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, notification, Space } from "antd";
import { Link } from "react-router-dom";
import { privacy } from "../../../urls";
import { initializeAnalytics } from "../../../services/firebase";

const Context = React.createContext({ name: "Default" });

const CookieBanner = () => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const cookieConsent = localStorage.getItem("consentGranted");
  const [analyticsLoaded, setAnalyticsLoaded] = React.useState(false);

  useEffect(() => {
    if (!cookieConsent) {
      openNotification("bottomRight");
    } else if (cookieConsent === "true" && !analyticsLoaded) {
      setGtag();
      initializeAnalytics();
      setAnalyticsLoaded(true);
    }
  }, [cookieConsent]);

  const contextValue = useMemo(() => ({ name: "Cookies" }), []);

  const openNotification = (placement) => {
    const btn = (
      <Space>
        <Link to={privacy}>
          <Button type="link" size="small">
            {t("read_more")}
          </Button>
        </Link>
        <Button size="small" onClick={handleDecline}>
          {t("cookiebar_decline")}
        </Button>
        <Button type="primary" size="small" onClick={handleAccept}>
          {t("cookiebar_accept")}
        </Button>
      </Space>
    );
    api.open({
      message: t("cookiebar_title"),
      description: t("cookiebar_description"),
      duration: 0,
      closeIcon: null,
      placement,
      btn,
    });
  };

  const handleAccept = () => {
    setGtag();
    localStorage.setItem("consentGranted", "true");
    initializeAnalytics();
    setAnalyticsLoaded(true);
    api.destroy();
  };

  const handleDecline = () => {
    api.destroy();
    localStorage.setItem("consentGranted", "false");
  };

  const setGtag = () => {
    function gtag() {
      window?.dataLayer.push(arguments);
    }

    gtag("consent", "update", {
      ad_user_data: "granted",
      ad_personalization: "granted",
      ad_storage: "granted",
      analytics_storage: "granted",
    });

    // Load Google Tag Manager script.
    let gtmScript = document.createElement("script");
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-K29B3JV');`;

    // Load Google Tag Manager noscript tag.
    let gtmNoScript = document.createElement("noscript");
    let iframe = document.createElement("iframe");
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-K29B3JV";
    iframe.height = "0";
    iframe.width = "0";
    iframe.style = "display:none;visibility:hidden";
    gtmNoScript.appendChild(iframe);

    document.head.appendChild(gtmScript);
    document.body.insertBefore(gtmNoScript, document.body.firstChild);
  };

  return (
    <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
  );
};

export default CookieBanner;
