import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

export const DIGITAL_KISS = "Digital KISS";
export const DIGITAL_KISS_LOGO = "/assets/img/logo/logo-digitalkiss-text.png";
export const WEBSITE_NAME = "MA-RK Living";
export const WEBSITE_PHONE_NUMBER = "+31 33 879 5526";
export const WEBSITE_EMAIL_ADDRESS = "hello@ma-rk.nl";
export const WEBSITE_STREET_NUMBER = "Wiekenweg 43-45";
export const WEBSITE_ZIPCODE = "3815 KL";
export const WEBSITE_CITY = "Amersfoort";
export const GOOGLE_MAPS_LINK = "https://maps.app.goo.gl/tqGShDP7NSCetjGy6";
export const GOOGLE_MAPS_LAT = "52.30713649848584";
export const GOOGLE_MAPS_LON = "5.140714610562966";
export const SOCIAL_MEDIA = [
  {
    icon: faInstagram,
    link: "https://instagram.com/ma.rk_living?igshid=YmMyMTA2M2Y=",
    name: "Facebook",
    value: "@ma-rk",
  },
  {
    icon: faFacebook,
    link: "https://www.facebook.com/profile.php?id=100091460496656",
    name: "Facebook",
    value: "@ma-rk",
  },
  {
    icon: faTiktok,
    link: "https://www.tiktok.com/@ma.rk_living",
    name: "TikTok",
    value: "@ma-rk",
  },
  {
    icon: faPinterest,
    link: "https://nl.pinterest.com/marklivingnl/",
    name: "TikTok",
    value: "@ma-rk",
  },
];
export const LOGO_STORAGE_SRC =
  process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
  "/o/global%2Flogo.jpg?alt=media";
export const PRICE_RANGE_INDICATOR = "€";
