import i18n from "../../translations/i18n";
import { message } from "antd";

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";
export const UPDATE_WISHLIST = "UPDATE_WISHLIST";

// add to wishlist
export const addToWishlist = (item) => {
  return (dispatch) => {
    message.success(i18n.t("added_to_wishlist"), 1);
    dispatch({ type: ADD_TO_WISHLIST, payload: item });
  };
};

// delete from wishlist
export const deleteFromWishlist = (item) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FROM_WISHLIST, payload: item });
  };
};

// delete all from wishlist
export const deleteAllFromWishlist = () => {
  return (dispatch) => {
    dispatch({ type: DELETE_ALL_FROM_WISHLIST });
  };
};

// update wishlist
export const updateWishlist = (products) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_WISHLIST, payload: products });
  };
};
