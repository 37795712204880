import i18n from "../../translations/i18n";
import { message } from "antd";

export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const DELETE_FROM_COMPARE = "DELETE_FROM_COMPARE";

// add to compare
export const addToCompare = (item) => {
  return (dispatch) => {
    message.success(i18n.t("added_to_compare"), 1);
    dispatch({ type: ADD_TO_COMPARE, payload: item });
  };
};

// delete from compare
export const deleteFromCompare = (item) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FROM_COMPARE, payload: item });
  };
};
