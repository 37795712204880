import React, { lazy, Suspense, useEffect } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./translations/i18n";
import urls from "./urls";
import ForgotPassword from "./pages/other/ForgotPassword";
import { useFirestoreConnect } from "react-redux-firebase";
import { Loader } from "./components/loader/Loader";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import { ORDER_STATUS_CREATED } from "./constants/OrderConstants";
import { updateCart } from "./redux/actions/cartActions";
import CookieBanner from "./components/banner/info/CookieBanner";
import {
  FIRESTORE_FEATURED_PRODUCTS_TABLE,
  FIRESTORE_ORDERS_TABLE,
  FIRESTORE_PRODUCTS_TABLE,
} from "./constants/FirebaseConstants";
import ReactPixel from "react-facebook-pixel";
import { ConfigProvider, notification } from "antd";
import { colors } from "./utils/theme";
import moment from "moment";
import { useTranslation } from "react-i18next";
import isBetween from "dayjs/plugin/isBetween";

dayjs.locale("nl");
dayjs.extend(isBetween);

const Home = lazy(() => import("./pages/home/Home"));
// shop pages
const ShopListStandard = lazy(() => import("./pages/shop/ShopList"));
const CategoryLandingPage = lazy(() => import("./pages/shop/CategoryLanding"));
const Dashboard = lazy(() => import("./pages/shop/Dashboard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const MyOrders = lazy(() => import("./pages/other/MyOrders"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const VerifyEmail = lazy(() => import("./pages/other/VerifyEmail"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const OrderConfirmed = lazy(() => import("./pages/other/OrderConfirmed"));
const OrderFailed = lazy(() => import("./pages/other/OrderFailed"));
const OrderProcessing = lazy(() => import("./pages/other/OrderProcessing"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const Returns = lazy(() => import("./pages/other/Returns"));
const TermsConditions = lazy(() => import("./pages/other/TermsConditions"));
const FAQ = lazy(() => import("./pages/other/FAQ"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  const { t } = useTranslation();
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  let queries = [
    { collection: FIRESTORE_PRODUCTS_TABLE },
    { collection: FIRESTORE_FEATURED_PRODUCTS_TABLE },
  ];
  // If user is logged in, get his/her orders
  if (!!email)
    queries.push({
      collection: FIRESTORE_ORDERS_TABLE,
      where: [
        ["customer.email", "==", email],
        ["orderStatus", "!=", ORDER_STATUS_CREATED],
      ],
    });
  useFirestoreConnect(queries);

  const dispatch = useDispatch();
  const products = useSelector(
    (state) => state.firestoreReducer.ordered.products
  );

  useEffect(() => {
    dispatch(updateCart(products));
  }, [products]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      const fbOptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init("600665668657492", null, fbOptions);
    }
    showVacationNotification();
  }, []);

  const showVacationNotification = () => {
    const today = moment();
    if (
      today.isBetween(
        moment("25-07-2024", "DD-MM-YYYY"),
        moment("18-08-2024", "DD-MM-YYYY")
      )
    )
      notification.info({
        message: t("vacation_notification_title"),
        description: t("vacation_notification_description"),
        duration: 14,
        placement: "bottomRight",
      });
  };

  if (!products) return <Loader />;
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Dropdown: {
              colorBgElevated: colors.bg,
            },
          },
        }}
      >
        <Router>
          <ScrollToTop>
            <Suspense fallback={<Loader />}>
              <Switch>
                {/* Homepage */}
                <Route exact path={urls.home} component={Home} />

                {/* Shop pages */}
                <Route exact path={urls.shop} component={ShopListStandard} />
                <Route
                  exact
                  path={urls.shopLandingPage}
                  component={Dashboard}
                />
                <Route
                  exact
                  path={urls.productsByCategory}
                  component={ShopListStandard}
                />

                {/* Shop product pages */}
                <Route
                  path={urls.productDetail}
                  render={(routeProps) => (
                    <Product
                      {...routeProps}
                      key={routeProps.match.params.id}
                      clickedProduct={routeProps.location.product}
                    />
                  )}
                />

                {/* Category landing page */}
                <Route
                  exact
                  path={urls.categoryLandingPage}
                  component={CategoryLandingPage}
                />

                {/* Blog pages */}
                <Route path={urls.blog} component={BlogStandard} />
                <Route
                  path={"/blog-details-standard"}
                  component={BlogDetailsStandard}
                />

                {/* Other pages */}
                <Route path={urls.about} component={About} />
                <Route path={urls.contact} component={Contact} />
                <Route path={urls.my_account} component={MyAccount} />
                <Route path={urls.my_orders} component={MyOrders} />
                <Route path={urls.login_register} component={LoginRegister} />
                <Route path={urls.forgot_password} component={ForgotPassword} />
                <Route path={urls.email_verified} component={VerifyEmail} />
                <Route path={urls.privacy} component={PrivacyPolicy} />
                <Route
                  path={urls.terms_conditions}
                  component={TermsConditions}
                />

                <Route path={urls.cart} component={Cart} />
                <Route path={urls.wishlist} component={Wishlist} />
                <Route path={urls.compare} component={Compare} />
                <Route path={urls.checkout} component={Checkout} />
                <Route path={urls.order_confirmed} component={OrderConfirmed} />
                <Route path={urls.order_failed} component={OrderFailed} />
                <Route
                  path={urls.order_processing + urls.document_id_param}
                  component={OrderProcessing}
                />
                <Route path={urls.not_found} component={NotFound} />
                <Route path={urls.returns} component={Returns} />
                <Route path={urls.faq} component={FAQ} />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
          <CookieBanner />
        </Router>
      </ConfigProvider>
    </>
  );
};

export default App;
