import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, styles } from "../../utils/theme";
import { device } from "../../utils/breakpoints";
import { transparentize } from "../../utils/helperFn";

const CtaLink = ({ block = false, children, ...rest }) => {
  return (
    <StyledLink block={block ? 1 : 0} {...rest}>
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  display: ${({ block }) => (block ? "block" : "inline-block")};
  border-radius: ${styles.btn_border_radius};

  padding: 10px 20px;
  background-color: ${colors.cta_btn};
  color: ${colors.cta_btn_text};
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  padding-right: 3rem;
  padding-left: 3rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: bolder;
  width: fit-content;
  &:hover,
  &:active,
  &:focus {
    color: ${colors.cta_btn_text};
    box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.15);
    transform: translateY(-7px);
  }

  @media ${device.lg} {
    padding: 14px 46px;
  }
`;

export default CtaLink;
