import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";
import { useTranslation } from "react-i18next";
import urls from "../../urls";
import _ from "lodash";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { device } from "../../utils/breakpoints";

const IconGroup = ({
  cartData,
  wishlistData,
  deleteFromCart,
  iconWhiteClass,
  auth,
  mobile = false,
}) => {
  const menuCartRef = useRef();
  const cartBtnRef = useRef();
  const accountCardRef = useRef();
  const accountBtnRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleScreenClick);
    return () => document.removeEventListener("click", handleScreenClick);
  }, []);

  /** Close cart modal if it is open and user clicks outside of the element*/
  const handleScreenClick = (e) => {
    // Close Cart Modal if opened
    if (!!menuCartRef.current && !!cartBtnRef.current) {
      if (
        !!menuCartRef.current &&
        !menuCartRef.current.contains(e.target) &&
        !cartBtnRef.current.contains(e.target) &&
        menuCartRef.current.className.includes("active") &&
        e.target.id !== "delete-cart-item"
      ) {
        menuCartRef.current.classList.remove("active");
      }
    }
    // Close Account Modal if opened
    if (!!accountCardRef.current && !!accountBtnRef.current) {
      if (
        !!accountCardRef.current &&
        !accountCardRef.current.contains(e.target) &&
        !accountBtnRef.current.contains(e.target) &&
        accountCardRef.current.className.includes("active")
      ) {
        accountCardRef.current.classList.remove("active");
      }
    }
  };

  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  const { t } = useTranslation();

  let cartItemsAmount = _.sumBy(cartData, "quantity");

  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      {/*<LanguageChanger small />*/}
      <div className="same-style account-setting d-none d-lg-block">
        <button
          className="account-setting-active"
          onClick={(e) => handleClick(e)}
          ref={accountBtnRef}
        >
          <AccountIcon />
        </button>
        <div className="account-dropdown" ref={accountCardRef}>
          {!auth.isEmpty ? (
            <ul>
              <li>
                <Link to={urls.my_account}>{t("my_account")}</Link>
              </li>
              <li>
                <Link to={urls.my_orders}>{t("my_orders")}</Link>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Link to={{ pathname: urls.login_register, navKey: "login" }}>
                  {t("login")}
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: urls.login_register, navKey: "register" }}
                >
                  {t("register")}
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
      {/*{!mobile && (*/}
      {/*  <>*/}
      {/*    <div className="same-style header-wishlist d-flex d-lg-block">*/}
      {/*      <Link to={urls.wishlist}>*/}
      {/*        <i className="pe-7s-like" />*/}
      {/*        <Counter>*/}
      {/*          <span>*/}
      {/*            {wishlistData && wishlistData.length*/}
      {/*              ? wishlistData.length*/}
      {/*              : 0}*/}
      {/*          </span>*/}
      {/*        </Counter>*/}
      {/*      </Link>*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}
      <div className="same-style cart-wrap d-none d-lg-block">
        <button
          className="icon-cart"
          onClick={(e) => handleClick(e)}
          ref={cartBtnRef}
        >
          <CartIcon />
          <Counter>
            <span>{cartItemsAmount}</span>
          </Counter>
        </button>
        {/* menu cart */}
        <MenuCart
          cartData={cartData}
          deleteFromCart={deleteFromCart}
          reference={menuCartRef}
        />
      </div>
      <div className="same-style cart-wrap d-flex d-lg-none">
        <Link className="icon-cart" to={urls.cart}>
          <CartIcon />
          <Counter>
            <span>{cartItemsAmount}</span>
          </Counter>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-flex d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

const Counter = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  top: -12px;
  right: -9px;
  width: 20px;
  height: 20px;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  background-color: ${colors.primary};

  span {
    align-self: center;
  }
`;

const AccountIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: #333;
  -webkit-mask: url("/assets/img/button-icons/account.svg") no-repeat center /
    contain;
  mask: url("/assets/img/button-icons/account.svg") no-repeat center / contain;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${colors.primary};
  }
`;

const CartIcon = styled.div`
  width: 25px;
  height: 25px;
  background-color: #333;
  -webkit-mask: url("/assets/img/button-icons/cart.svg") no-repeat center /
    contain;
  mask: url("/assets/img/button-icons/cart.svg") no-repeat center / contain;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${colors.primary};
  }

  @media ${device.lg} {
    width: 32px;
    height: 32px;
  }
`;

IconGroup.propTypes = {
  cartData: PropTypes.array,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    auth: state.firebaseReducer.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (item) => {
      dispatch(deleteFromCart(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
