import PropTypes from "prop-types";
import React, { Fragment } from "react";
import HeaderOne from "../wrappers/header/Header";
import Footer from "../wrappers/footer/Footer";
import HeaderTop from "../components/header/HeaderTop";
import topHeaderData from "../data/header/top-header.json";

const Layout = ({ footerImage = null, children }) => {
  return (
    <Fragment>
      <HeaderTop data={topHeaderData} />
      <HeaderOne />
      {children}
      <Footer bgImage={footerImage} />
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
};

export default Layout;
