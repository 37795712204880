import React from "react";
import { SOCIAL_MEDIA } from "../../../constants/CompanyConstants";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { device } from "../../../utils/breakpoints";

const MobileWidgets = () => {
  return (
    <Wrapper>
      <div className={"d-flex justify-content-center"}>
        {SOCIAL_MEDIA.map((item, key) => {
          return (
            <SocialIcon
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className={"d-flex"}
              key={key}
            >
              <Icon icon={item.icon} />
            </SocialIcon>
          );
        })}
      </div>
    </Wrapper>
  );
};

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  a {
  }
  i {
    font-size: 28px;
  }
`;

const Wrapper = styled.div`
  padding: 2rem 2rem;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  align-self: center;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: #252525;
  transition: all 0.2s ease;
  padding-left: 6px;
  padding-right: 6px;

  &:hover {
    opacity: 0.7;
  }
`;

const SocialIcon = styled.a`
  @media ${device.maxLg} {
    margin: 1rem 0 !important;
  }
`;

export default MobileWidgets;
