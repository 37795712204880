import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  about,
  categorySlug,
  contact,
  shop,
  shopLandingPage,
} from "../../urls";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { transparentize } from "../../utils/helperFn";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import i18n from "i18next";

const NavMenu = () => {
  const { t } = useTranslation();
  return (
    <StyledNav>
      <ul>
        <li>
          <Dropdown
            menu={{
              items: categoryItems,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {t("categories")}
                <DownOutlined className={"d-flex"} />
              </Space>
            </a>
          </Dropdown>
        </li>
        <li>
          <NavLink to={shopLandingPage} activeClassName="active" exact>
            {t("shop")}
          </NavLink>
        </li>
        <li>
          <NavLink to={about} activeClassName="active" exact>
            {t("about")}
          </NavLink>
        </li>
        <li>
          <NavLink to={contact} activeClassName="active" exact>
            {t("contact_us")}
          </NavLink>
        </li>
      </ul>
    </StyledNav>
  );
};

const StyledNavLink = styled(NavLink)`
  &.active {
    color: ${colors.tertiary} !important;
  }
`;

const StyledNav = styled.nav`
  ul {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      border-right: 1px solid ${colors.primary30};

      a {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        color: ${colors.primary};

        &:hover,
        &:active,
        &.active {
          // color: ${transparentize(colors.primary, 0.7)};
          color: ${colors.tertiary};
        }
      }
    }
  }
`;

const categoryItems = [
  {
    key: "1",
    label: (
      <StyledNavLink
        to={categorySlug + "vine"}
        activeClassName="active"
        exact
        style={{ fontSize: 18, letterSpacing: 1 }}
      >
        {i18n.t("vine_category_desc")}
      </StyledNavLink>
    ),
  },
  {
    key: "2",
    label: (
      <StyledNavLink
        to={categorySlug + "side"}
        activeClassName="active"
        exact
        style={{ fontSize: 18, letterSpacing: 1 }}
      >
        {i18n.t("side_category_desc")}
      </StyledNavLink>
    ),
  },
  {
    key: "3",
    label: (
      <StyledNavLink
        to={categorySlug + "night"}
        activeClassName="active"
        exact
        style={{ fontSize: 18, letterSpacing: 1 }}
      >
        {i18n.t("night_category_desc")}
      </StyledNavLink>
    ),
  },

  {
    key: "4",
    label: (
      <StyledNavLink
        to={categorySlug + "salon"}
        activeClassName="active"
        exact
        style={{ fontSize: 18, letterSpacing: 1 }}
      >
        {i18n.t("salon_category_desc")}
      </StyledNavLink>
    ),
  },

  {
    key: "5",
    label: (
      <StyledNavLink
        to={categorySlug + "view"}
        activeClassName="active"
        exact
        style={{ fontSize: 18, letterSpacing: 1 }}
      >
        {i18n.t("view_category_desc")}
      </StyledNavLink>
    ),
  },
];

export default NavMenu;
