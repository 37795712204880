import React from "react";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";
import { colors } from "../../utils/theme";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

const HeaderTop = ({ data }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <Wrapper fluid>
      <Swiper
        slidesPerView={1}
        modules={[Autoplay]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {data[lang].map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <ItemWrapper>
                <SellingPoint>{item.text}</SellingPoint>
              </ItemWrapper>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Wrapper>
  );
};

const SellingPoint = styled.span`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.9px;
  color: white;
  padding: 8px 0 10px 0;
`;

const Icon = styled.img`
  max-height: 24px;
  align-self: center;
  margin-right: 1rem;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg)
    brightness(105%) contrast(102%);
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid ${colors.primary30};
  background-color: ${colors.primary};

  @media ${device.maxSm} {
    display: block;
  }
`;

export default HeaderTop;
