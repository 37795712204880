import ReactPixel from "react-facebook-pixel";

export const fbTrackPageView = () => {
  ReactPixel.pageView();
};

export const fbTrackViewContent = (data) => {
  ReactPixel.track("ViewContent", data);
};

export const fbTrackPurchase = (data) => {
  ReactPixel.track("Purchase", data);
};

export const fbTrackAddToCart = (data) => {
  ReactPixel.track("AddToCart", data);
};

export const fbTrackInitiateCheckout = (data) => {
  ReactPixel.track("InitiateCheckout", data);
};

export const fbTrackCompleteRegistration = () => {
  ReactPixel.track("CompleteRegistration");
};

export const fbTrackContact = () => {
  ReactPixel.track("Contact");
};
