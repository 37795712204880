import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  about,
  categorySlug,
  contact,
  login_register,
  my_account,
  my_orders,
  shopLandingPage,
} from "../../../urls";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { colors } from "../../../utils/theme";

const MobileNavMenu = ({ closeMenu }) => {
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const { t } = useTranslation();
  return (
    <StyledNav>
      <ul>
        <li>
          <MenuItem to={shopLandingPage} onClick={closeMenu}>
            {t("shop")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={categorySlug + "vine"} onClick={closeMenu}>
            - {t("vine_category_desc")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={categorySlug + "side"} onClick={closeMenu}>
            - {t("side_category_desc")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={categorySlug + "night"} onClick={closeMenu}>
            - {t("night_category_desc")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={categorySlug + "salon"} onClick={closeMenu}>
            - {t("salon_category_desc")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={categorySlug + "view"} onClick={closeMenu}>
            - {t("view_category_desc")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={about} onClick={closeMenu}>
            {t("about")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={contact} onClick={closeMenu}>
            {t("contact_us")}
          </MenuItem>
        </li>
      </ul>
    </StyledNav>
  );
};

const MenuItem = styled(Link)`
  display: block;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.65rem 0;
  color: ${colors.menu_items};
`;

const StyledNav = styled.nav`
  padding: 0 1rem 1rem 2rem;
`;

export default MobileNavMenu;
