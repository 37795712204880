import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Col, Row } from "antd";
import {
  about,
  categorySlug,
  contact,
  privacy,
  returns,
  terms_conditions,
  shopLandingPage,
} from "../../urls";
import { Link } from "react-router-dom";
import { colors } from "../../utils/theme";
import { device } from "../../utils/breakpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SOCIAL_MEDIA } from "../../constants/CompanyConstants";

const Footer = ({ bgImage }) => {
  const { t } = useTranslation();

  return (
    <>
      {!!bgImage && <BgImage bgImage={bgImage} />}
      <StyledFooter>
        <ColorBg>
          <Row>
            <Col xs={10} lg={10} className={"align-self-center text-center"}>
              <LinksWrapper>
                <Link to={categorySlug + "vine"}>{t("vine_category")}</Link>
                <Link to={categorySlug + "side"}>{t("side_category")}</Link>
                <Link to={categorySlug + "night"}>{t("night_category")}</Link>
                <Link to={categorySlug + "salon"}>{t("salon_category")}</Link>
                <Link to={categorySlug + "view"}>{t("view_category")}</Link>
              </LinksWrapper>
            </Col>
            <Col xs={4} lg={4} className={"text-center"}>
              <Link to={"/"}>
                <Logo src={"/assets/img/logo/logo-white-sm.png"} alt={"logo"} />
              </Link>
            </Col>
            <Col xs={10} lg={10} className={"align-self-center text-center"}>
              <LinksWrapper>
                <Link to={shopLandingPage}>{t("shop")}</Link>
                <Link to={about}>{t("about")}</Link>
                <Link to={returns}>{t("returns")}</Link>
                <Link to={contact}>{t("contact")}</Link>
              </LinksWrapper>
            </Col>
          </Row>
          <BottomRow>
            <Col lg={10}>
              <div className={"text"}>
                <PoweredBy className={"desktop"}>
                  <p>{t("powered_by")}</p>
                  <a href={"https://digitalkiss.nl/"} target={"_blank"}>
                    <img
                      width={"100px"}
                      src={"/assets/img/logo/logo-digitalkiss-white-text.png"}
                      alt={"Digital KISS"}
                    />
                  </a>
                </PoweredBy>
                <p>Wiekenweg 43-45, 3815 KL, Amersfoort</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className={"d-flex justify-content-center"}>
                {SOCIAL_MEDIA.map((item, key) => {
                  return (
                    <SocialIcon
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={"d-flex"}
                      key={key}
                    >
                      <Icon icon={item.icon} />
                    </SocialIcon>
                  );
                })}
              </div>
            </Col>
            <Col lg={10}>
              <div className={"text"}>
                <Link to={privacy}>{t("privacy_policy")}</Link>
                <Link to={terms_conditions}>{t("terms_and_conditions")}</Link>
                <PoweredBy className={"mobile"}>
                  <p>{t("powered_by")}</p>
                  <a href={"https://digitalkiss.nl/"} target={"_blank"}>
                    <img
                      src={"/assets/img/logo/logo-digitalkiss-white-text.png"}
                      alt={"Digital KISS"}
                    />
                  </a>
                </PoweredBy>
              </div>
            </Col>
          </BottomRow>
        </ColorBg>
      </StyledFooter>
    </>
  );
};

const SocialIcon = styled.a`
  @media ${device.maxLg} {
    margin: 1rem 0 !important;
  }
`;

const PoweredBy = styled.div`
  display: flex;
  align-items: center;

  img {
    line-height: 0.8;
  }

  &.desktop {
    display: none;
  }

  &.mobile {
    margin-top: 2rem;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    p {
      display: none;
    }
  }

  @media ${device.lg} {
    &.mobile {
      display: none;
    }
    &.desktop {
      display: flex;
    }
  }
`;

const BottomRow = styled(Row)`
  margin-top: 2.5rem;

  @media ${device.maxLg} {
    flex-direction: column;
  }

  p,
  a {
    color: white;
    opacity: 0.8;
    margin: 0;
  }

  img {
    max-height: 14px;
    margin-left: 6px;
  }

  .text {
    display: flex;
    justify-content: space-around;

    @media ${device.maxLg} {
      flex-direction: column;
      align-items: center;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  align-self: center;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: #fff;
  transition: all 0.2s ease;
  padding-left: 6px;
  padding-right: 6px;

  &:hover {
    opacity: 0.7;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  @media ${device.lg} {
    flex-direction: row;
  }

  a {
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;

    @media ${device.maxLg} {
      margin-bottom: 0.5rem;
    }
  }
`;

const Logo = styled.img`
  width: fill-available;
  max-width: 70px;

  @media ${device.maxLg} {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const ColorBg = styled.footer`
  background-color: ${colors.primary};
  padding-bottom: 1rem;
  padding-top: 2rem;
`;

const BgImage = styled.div`
  padding-top: 35vh;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
`;

const StyledFooter = styled.footer`
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
`;

export default Footer;
