// Meta tags titles
export const META_ABOUT_TITLE =
  "Minimalistische stalen meubels uit Amersfoort: MA-RK Living";
export const META_CHECKOUT_TITLE = "Afrekenen";
export const META_COMPARE_TITLE = "Vergelijken";
export const META_CONTACT_TITLE = "Contact";
export const META_CART_TITLE = "Winkelwagen";
export const META_RESET_PASSWORD_TITLE = "Wachtwoord Resetten";
export const META_LOGIN_REGISTER_TITLE = "Inloggen - Registreren";
export const META_MY_ACCOUNT_TITLE = "Mijn Account";
export const META_ORDERS_TITLE = "Bestellingen";
export const META_NOT_FOUND_TITLE = "Niet Gevonden";
export const META_ORDER_CONFIRMED_TITLE = "Bestelling Bevestigd";
export const META_ORDER_FAILED_TITLE = "Betaling Mislukt";
export const META_WISHLIST_TITLE = "Favorieten";
export const META_BLOG_TITLE = "Blog";
export const META_PRIVACY_TITLE = "Privacy Beleid";
export const META_TERMS_CONDITIONS_TITLE = "Algemene Voorwaarden";
export const META_DELIVERY_RETURNS_TITLE = "Verzenden & retourneren";
export const META_FAQ_TITLE = "Veelgestelde Vragen";

// Meta tags descriptions
export const META_GENERIC_DESCRIPTION =
  "Stalen meubels met industrieel en modern design. Ontworpen door meubelzaak MA-RK Living in Amersfoort.";
export const META_PRODUCT_DESCRIPTION =
  "Met een stalen meubel van MA-RK Living koop je kwaliteit uit Nederland. Ieder product van MA-RK Living wordt met veel zorg en aandacht gemaakt in Amersfoort. Bekijk onze producten.";
export const META_ABOUT_DESCRIPTION =
  "Met een stalen meubel van MA-RK Living koop je kwaliteit uit Nederland. Ieder product van MA-RK Living wordt met veel zorg en aandacht gemaakt in Amersfoort. Bekijk onze producten.";
export const META_CONTACT_DESCRIPTION =
  "Vragen of opmerkingen over MA-RK Living? Vul het contactformulier in en wij nemen direct contact met je op!";
export const META_RESET_PASSWORD_DESCRIPTION =
  "Je wachtwoord vergeten? Vraag direct een nieuwe aan via deze pagina.";
export const META_LOGIN_REGISTER_DESCRIPTION =
  "Log in bij MA-RK om al je bestellingen in te zien of om een recente bestelling opnieuw te plaatsen!";
export const META_ORDERS_DESCRIPTION =
  "Al jouw MA-RK Living bestellingen op een rij. Hier kun je ze opnieuw bestellen of exporteren!";
export const META_NOT_FOUND_DESCRIPTION =
  "Oeps, je bent naar een pagina gegaan die bij ons niet (meer) bestaat.";
export const META_BLOG_DESCRIPTION =
  "Benieuwd naar de nieuwste trends binnen MA-RK Living? Bekijk het op ons blog!";

export const META_CATEGORY_SLOGAN = "Minimalistisch en modern design";
