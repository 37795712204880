import { transparentize } from "./helperFn";

export const colors = {
  primary: "#082e57",
  primary80: transparentize("#082e57", 0.8),
  primary30: transparentize("#082e57", 0.3),
  primary20: transparentize("#082e57", 0.2),
  primary10: transparentize("#082e57", 0.1),
  secondary: "#af9464",
  tertiary: "#98b4f2",
  bg: "#fdf7ef",
  header: "#082e57",
  text: "#3B3F33",
  bannerText: "#919191",
  btn: "#4B5A57",
  cta_btn: "#98b4f2",
  cta_btn_text: "#fff",
  standout_text: "#579b3c",
  added_to_cart_btn: "#009d00",
  category_header: "#e5e5e5",
  clickable_text: "#082e57",
  hamburger: "#082e57",
  menu_items: "#222",
};

export const styles = {
  btn_border_radius: "50px",
  card_border_radius: "4px",
  bullet_point_icon: "•  ",
};
