import React from "react";
import MobileNavMenu from "./sub-components/MobileNavMenu";
import MobileLangCurChange from "./sub-components/MobileLanguageChanger";
import MobileWidgets from "./sub-components/MobileWidgets";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import MobileNavMenuAccount from "./sub-components/MobileNavMenuAccount";

const MobileMenu = ({ active, closeMenu }) => {
  return (
    <Wrapper className={active ? "active" : ""}>
      <Content>
        {/* mobile nav menu */}
        <MobileNavMenu closeMenu={closeMenu} />
        <Divider />
        <MobileNavMenuAccount />
        <Divider />

        {/* mobile language and currency */}
        {/*<MobileLangCurChange />*/}
        {/* mobile widgets */}
        <MobileWidgets />
      </Content>
    </Wrapper>
  );
};

const StyleElement = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  img {
    max-width: 50vw;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 0;
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background: ${colors.bg};
  /* to stop flickering of text in safari */
  -webkit-font-smoothing: antialiased;
  transform: translate(-100%, 0);
  transition: transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1);

  &.active {
    transform: translate(0, 0);
  }
`;

export default MobileMenu;
