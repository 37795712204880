import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = (props) => {
  const preventScroll =
    !!props.location.state && props.location.state.preventScroll;
  useEffect(() => {
    if (!preventScroll) window.scrollTo(0, 0);
  });
  return props.children;
};

export default withRouter(ScrollToTop);
