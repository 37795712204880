import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";
import { Link, useLocation } from "react-router-dom";
import urls from "../../urls";
import { colors } from "../../utils/theme";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Col, Row } from "antd";
import { Container } from "react-bootstrap";

const HeaderOne = () => {
  const cartData = useSelector((state) => state.cartData);
  const [menuOpen, setMenuOpen] = useState(false);
  const headerRef = useRef(null);
  const spotRef = useRef(null);
  const location = useLocation();
  const hideNavBarItems =
    location.pathname === urls.cart || location.pathname === urls.checkout;
  let cartItemsAmount = _.sumBy(cartData, "quantity");

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshhold: 0,
    };
    const observer = new IntersectionObserver(handleScroll, options);
    observer.observe(spotRef.current);
  }, []);

  const handleScroll = (entries) => {
    if (!!headerRef && headerRef.current) {
      const spotIsVisible = entries[0].isIntersecting;
      if (spotIsVisible) headerRef.current.classList.remove("fixed-top");
      else headerRef.current.classList.add("fixed-top");
    }
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <DesktopHeader className={"header-area clearfix"} ref={headerRef}>
        <Container fluid>
          <Row style={{ height: 75 }}>
            <DesktopCol lg={4}>
              {/* header logo */}
              <Logo
                imageUrl="/assets/img/logo/logo-blue.png"
                logoClass="logo"
              />
            </DesktopCol>
            <DesktopCol lg={12}>{!hideNavBarItems && <NavMenu />}</DesktopCol>
            <DesktopCol lg={6} style={{ height: 50 }} />
            <DesktopCol lg={2} className={"border-0"}>
              {/* Icon group */}
              <IconGroup />
            </DesktopCol>
          </Row>
        </Container>
      </DesktopHeader>
      <div className="spot" ref={spotRef} />
      <MobileHeader>
        <div className={`sticky-bar header-res-padding clearfix bg-shadow`}>
          <MobileItemsWrapper>
            <HamburgerMenu
              onClick={() => setMenuOpen(!menuOpen)}
              className={menuOpen ? "active" : ""}
            >
              {/*<input />*/}
              <span />
              <span />
              <span />
            </HamburgerMenu>
            {/* header logo */}
            <Logo
              imageUrl="/assets/img/logo/logo-blue.png"
              logoClass="logo"
              closeMenu={closeMenu}
            />
            <CartLink className="icon-cart" to={urls.cart}>
              <CartIcon />
              <Counter>
                <span>{cartItemsAmount}</span>
              </Counter>
            </CartLink>
          </MobileItemsWrapper>
          {/* mobile menu */}
          <MobileMenu active={menuOpen} closeMenu={closeMenu} />
        </div>
      </MobileHeader>
    </>
  );
};

const DesktopCol = styled(Col)`
  align-self: center;
  border-right: 1px solid ${colors.primary30};
`;

const HamburgerMenu = styled.div`
  display: block;
  position: relative;
  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
  align-self: center;
  margin-bottom: -7px;

  span {
    display: block;
    width: 28px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;

    background: ${colors.hamburger};
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  &.active {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(1px, -3px);
      background: ${colors.hamburger};
    }

    span:nth-last-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    span:nth-last-child(1) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
`;

const MobileItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const CartLink = styled(Link)`
  font-size: 36px;
  position: relative;
  display: inline-block;
  border: none;
  background: transparent;
  align-self: center;
  margin-bottom: -4px;
`;

const Counter = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  top: -12px;
  right: -9px;
  width: 20px;
  height: 20px;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  background-color: ${colors.primary};

  span {
    align-self: center;
  }
`;

const CartIcon = styled.div`
  width: 25px;
  height: 25px;
  background-color: #333;
  -webkit-mask: url("/assets/img/button-icons/cart.svg") no-repeat center /
    contain;
  mask: url("/assets/img/button-icons/cart.svg") no-repeat center / contain;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${colors.primary};
  }

  @media ${device.lg} {
    width: 32px;
    height: 32px;
  }
`;

const MobileHeader = styled.div`
  display: block;
  background-color: ${colors.bg};

  .sticky-bar {
    background-color: ${colors.bg};
  }
  @media ${device.lg} {
    display: none;
  }
`;

const DesktopHeader = styled.div`
  display: none;
  position: relative;
  border-bottom: 1px solid ${colors.primary30};
  background: ${colors.bg};
  z-index: 1000;

  &.fixed-top {
    position: fixed;
    width: 100%;
    transition: all 0.3s ease 0s;
    animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  }
  @media ${device.lg} {
    display: block;
  }
`;

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default HeaderOne;
