import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls from "../../../urls";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { colors } from "../../../utils/theme";

const MobileNavMenuAccount = () => {
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const { t } = useTranslation();
  return (
    <StyledNav>
      <ul>
        <li className="menu-item-has-children">
          {!auth.isEmpty ? (
            <ul className="sub-menu">
              <li>
                <MenuItem to={urls.my_account}>{t("my_account")}</MenuItem>
              </li>
              <li>
                <MenuItem to={urls.my_orders}>{t("my_orders")}</MenuItem>
              </li>
            </ul>
          ) : (
            <ul className="sub-menu">
              <li>
                <MenuItem
                  to={{ pathname: urls.login_register, navKey: "login" }}
                >
                  {t("login")}
                </MenuItem>
              </li>
              <li>
                <MenuItem
                  to={{ pathname: urls.login_register, navKey: "register" }}
                >
                  {t("register")}
                </MenuItem>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </StyledNav>
  );
};

const MenuItem = styled(Link)`
  display: block;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.65rem 0;
  color: ${colors.menu_items};
`;

const StyledNav = styled.nav`
  padding: 1rem 1rem 1rem 2rem;
`;

export default MobileNavMenuAccount;
